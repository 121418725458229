import React, {useState, useEffect, useCallback} from 'react';
import SearchIcon from "../icons/SearchIcon";
import ClearIcon from "../icons/ClearIcon";
import styles from './SearchPartner.module.css';
import $api from "../../../core/api";

const SearchPartner = ({ value, type, filterData, requestFilterData, setValue, isSearch, placeholder, label, onClick, maxWidth = "", style = {} }) => {
    const [isDisplay, setIsDisplay] = useState(false);
    const [filtered, setFiltered] = useState([]);

    // Рассчитывает релевантность совпадения результата поиска с искомым значением
    function calculatePartnerRelevance(item, searchTerm) {
        for (let key in item) {
            if (!item[key])
                continue;

            const haystack = String(item[key]).toLowerCase();
            const needed = searchTerm.toLowerCase();

            const weight = (haystack === needed) ? 3
                : haystack.startsWith(needed) ? 2
                    : haystack.includes(needed) ? 1
                        : 0;

            if (weight > 0)
                return weight;
        }

        return 0; // Нет совпадений
    }

    // Получаем результаты поиска из базы
    const loadPartners = useCallback(async () => {
        const inputValue = value;

        if (inputValue.length < 2) {
            setFiltered([]);
            return;
        }

        try {
            const response = await $api.get("/api/partners/fully-search-partners", {
                params: { searchTerm: inputValue }
            });

            if (response.data) {
                const options = response.data.map(partner => ({
                    login: partner.login,
                    affiseId: partner.affiseId,
                    email: partner.email,
                }));

                // Сортируем по релевантности
                options.sort((a, b) => {
                    const relevanceA = calculatePartnerRelevance(a, value.toLowerCase());
                    const relevanceB = calculatePartnerRelevance(b, value.toLowerCase());

                    return relevanceB - relevanceA; // Сортируем по убыванию релевантности
                });

                setFiltered(options);

                return;
            }

            setFiltered([]);
        } catch (err) {
            setFiltered([]);
        }
    }, [value]);

    useEffect(loadPartners, [loadPartners]);

    const handleClick = (el) => {
        const fields = [String(el.affiseId), el.email, el.login];

        for (let field of fields) {
            if (field.includes(value)) {
                setValue(field);
            }
        }
        setIsDisplay?.(false);
    };

    const handleOnFocus = () => {
        setIsDisplay?.(true);
    }

    const handleClear = () => {
        setValue('');
        setIsDisplay?.(false);
    }

    // eslint-disable-next-line no-unused-vars
    const handleOnBlurs = (e) => {
        if (value?.length) {
            setIsDisplay?.(false);
        } 
    }

    const handleKeyPress = (event) => {
        if (event?.charCode === 13) {
            if (filterData && requestFilterData) {
                requestFilterData(filterData);
                setIsDisplay?.(false);
            } else if (requestFilterData) {
                requestFilterData(value);
                setValue(value);
                setIsDisplay?.(false);
            }
        }
    }

    return (
        <div {...style} 
            className={styles.SearchContainer} 
            // onClick={onClick}
        >
            {label &&
                <label>{label}</label>
            }

            <div className={styles.SearchBlock} style={{ maxWidth: maxWidth }}>
                <SearchIcon />
                <input
                    data-lpignore="true"
                    className={`inputField ${isSearch ? 'pd-left' : ''}`}
                    onChange={(event) => setValue(event.target.value)}
                    value={value}
                    placeholder={placeholder}
                    onFocus={handleOnFocus}
                    onKeyPress={(event) => handleKeyPress(event)}
                    // onBlur={handleOnBlurs}
                />
                {value && <span onClick={handleClear} className={styles.ClearIcon}>
                    <ClearIcon  />
                </span>
                }
            </div>
            {isDisplay && value ? <div 
                className={filtered?.length ? styles.AutoComplitBlock: styles.AutoComplitNone}
            >
                
                {filtered?.length ? filtered?.map((el, index) => {
                return (
                    <span
                        className={styles.AutoComplitElement}
                        key={index}
                        onClick={() => handleClick(el)}
                    >
                        {el.affiseId} {el.login} {el.email}
                    </span>
                )}): ''}
            </div>: ''}
            
        </div>
    );
};

export default SearchPartner;